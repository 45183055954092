import { getCurrentUser } from "aws-amplify/auth";

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return;

  if (to.name === "login") return;

  try {
    const user = await getCurrentUser();
    if (!user) return navigateTo("/login");
  } catch {
    return navigateTo("/login");
  }
});

import "@mdi/font/css/materialdesignicons.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    components: {
      ...preloadComponents,
    },
    defaults: {
      VAppBar: {
        density: "compact",
      },
      VToolbar: {
        density: "compact",
      },
      VTextField: {
        density: "compact",
        variant: "outlined",
      },
      VTextarea: {
        density: "compact",
        variant: "outlined",
      },
      VSelect: {
        density: "compact",
        variant: "outlined",
      },
      VFileInput: {
        density: "compact",
        variant: "outlined",
        hideDetails: true,
        showSize: true,
      },
    },
  });
  app.vueApp.use(vuetify);
});

import { default as indexgvm0nA6iXEMeta } from "/workspace/cms/src/pages/index.vue?macro=true";
import { default as loginT8SUAJQzhZMeta } from "/workspace/cms/src/pages/login.vue?macro=true";
import { default as indexEQZ3mYLymUMeta } from "/workspace/cms/src/pages/resource/index.vue?macro=true";
import { default as createn1KpIiFYKoMeta } from "/workspace/cms/src/pages/room/create.vue?macro=true";
import { default as _91id_93KRB3n2CeYaMeta } from "/workspace/cms/src/pages/room/edit/[id].vue?macro=true";
import { default as indexGJ1Em3zgvgMeta } from "/workspace/cms/src/pages/room/index.vue?macro=true";
export default [
  {
    name: indexgvm0nA6iXEMeta?.name ?? "index",
    path: indexgvm0nA6iXEMeta?.path ?? "/",
    meta: indexgvm0nA6iXEMeta || {},
    alias: indexgvm0nA6iXEMeta?.alias || [],
    redirect: indexgvm0nA6iXEMeta?.redirect,
    component: () => import("/workspace/cms/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginT8SUAJQzhZMeta?.name ?? "login",
    path: loginT8SUAJQzhZMeta?.path ?? "/login",
    meta: loginT8SUAJQzhZMeta || {},
    alias: loginT8SUAJQzhZMeta?.alias || [],
    redirect: loginT8SUAJQzhZMeta?.redirect,
    component: () => import("/workspace/cms/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexEQZ3mYLymUMeta?.name ?? "resource",
    path: indexEQZ3mYLymUMeta?.path ?? "/resource",
    meta: indexEQZ3mYLymUMeta || {},
    alias: indexEQZ3mYLymUMeta?.alias || [],
    redirect: indexEQZ3mYLymUMeta?.redirect,
    component: () => import("/workspace/cms/src/pages/resource/index.vue").then(m => m.default || m)
  },
  {
    name: createn1KpIiFYKoMeta?.name ?? "room-create",
    path: createn1KpIiFYKoMeta?.path ?? "/room/create",
    meta: createn1KpIiFYKoMeta || {},
    alias: createn1KpIiFYKoMeta?.alias || [],
    redirect: createn1KpIiFYKoMeta?.redirect,
    component: () => import("/workspace/cms/src/pages/room/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KRB3n2CeYaMeta?.name ?? "room-edit-id",
    path: _91id_93KRB3n2CeYaMeta?.path ?? "/room/edit/:id()",
    meta: _91id_93KRB3n2CeYaMeta || {},
    alias: _91id_93KRB3n2CeYaMeta?.alias || [],
    redirect: _91id_93KRB3n2CeYaMeta?.redirect,
    component: () => import("/workspace/cms/src/pages/room/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGJ1Em3zgvgMeta?.name ?? "room",
    path: indexGJ1Em3zgvgMeta?.path ?? "/room",
    meta: indexGJ1Em3zgvgMeta || {},
    alias: indexGJ1Em3zgvgMeta?.alias || [],
    redirect: indexGJ1Em3zgvgMeta?.redirect,
    component: () => import("/workspace/cms/src/pages/room/index.vue").then(m => m.default || m)
  }
]